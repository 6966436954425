import { useQuery } from "@tanstack/react-query";

import * as API from "../api";

export function useOrderAssets({
    order = {},
    includeFormats = false,
    enabled,
}) {
    const { id: orderId, assets = [] } = order;
    const _enabled = enabled ?? !!orderId;
    return useQuery({
        queryKey: ["template-assets", orderId],
        queryFn: () => {
            return new Promise((resolve, reject) => {
                (async () => {
                    const assetPromises = assets.map((asset) => {
                        return API.getAsset(asset.id);
                    });

                    if (!includeFormats) {
                        const _assets = await Promise.all(assetPromises);
                        resolve(_assets);
                        return;
                    }
    
                    if (includeFormats) {
                        const fileFormats = await API.getFileFormats();
    
                        Promise.all(assetPromises)
                            .then((_assets) => {
                                const assetsWithFileFormats = _assets.map((asset) => {
                                    return {
                                        ...asset,
                                        allowedFileFormats: asset.allowedFileFormats?.map((allowedFileFormat) => {
                                            const fileFormat = fileFormats.find((fileFormat) => fileFormat.id === allowedFileFormat.id);
                                            return {
                                                ...allowedFileFormat,
                                                extension: fileFormat.extension,
                                            };
                                        }),
                                    };
                                });
                                resolve(assetsWithFileFormats);
                            })
                            .catch(reject);
                    }
                })();
            });
        },
        enabled: _enabled,
    });
}