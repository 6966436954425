import { lazy, Suspense, useEffect } from "react";
import { Route, Navigate, RouterProvider, createRoutesFromElements, createBrowserRouter, Outlet } from "react-router-dom";

import Header from "./Header";
const loadOrder = () => import("./Order");
const Order = lazy(loadOrder);
import Login from "./Login";
import Home from "./Home";
import UploadStatus from "./components/UploadStatus";

import { AuthContext, useAuth, useAuthInternal } from "./hooks/useAuth";
import { UploadsContext, useUploadsInternal } from "./hooks/useUploads";
import { OrdersContext, useOrdersInternal } from "./hooks/useOrders";

const AppShell = () => {
	return (
		<>
			<Header />
			<Outlet />
			<UploadStatus />
		</>
	);
};

const routes = (
	<Route element={<AppShell />}>
		<Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
		<Route path="/order" element={<ProtectedRoute><Suspense><Order /></Suspense></ProtectedRoute>} />
		<Route path="/login" element={<Login />} />
	</Route>
);
const router = createBrowserRouter(
	createRoutesFromElements(routes),
);

function App() {
	useEffect(
		() => {
			setTimeout(
				() => {
					loadOrder();
				},
				1000
			);
		},
		[]
	);
	
	return (
		<AuthProvider>
			<UploadsProvider>
				<OrdersProvider>
					<RouterProvider router={router} />
				</OrdersProvider>
			</UploadsProvider>
		</AuthProvider>
	);
}
	
export default App;

function ProtectedRoute({ children }) {
	const { username, initialized } = useAuth();
	if (!initialized) {
		return null;
	}

	if (!username) {
		return <Navigate to="/login" />;
	}
	
	return children;
}

function AuthProvider({ children }) {
	const auth = useAuthInternal();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function UploadsProvider({ children }) {
	const uploads = useUploadsInternal();
	return <UploadsContext.Provider value={uploads}>{children}</UploadsContext.Provider>;
}

function OrdersProvider({ children }) {
	const auth = useAuth();
	const orders = useOrdersInternal({ enabled: auth.isLoggedIn });
	return <OrdersContext.Provider value={orders}>{children}</OrdersContext.Provider>;
}
