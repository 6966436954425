// import { formatDuration/*, formatSize*/ } from "../utils";

import "./FileList.css";

function FileList(props) {
    const {
        files,
        onRemoveFile,
        includeDetails,
        includeThumbnail,
    } = props;

    return (
        <div className={`file-list ${includeDetails ? "with-details" : ""}`}>
            <div className="files">
                {files?.map((file) => {
                    // const progressPercentage = Math.round(file.loadedBytes / file.size * 100);
                    return (
                        <div className="file" key={file.id ?? file.name}>
                            {includeThumbnail && <img src={file.thumbnailURL} />}
                            <div className="file-info">
                                <div className="file-name" title={file.name}>{file.name}</div>
                                {/* {includeDetails && (
                                    <div className="file-details"> */}
                                        {/* {!isNaN(progressPercentage) && (
                                            <div className={`file-progress ${progressPercentage === 100 ? "hide": ""}`}>
                                                <div
                                                    className={`file-progress-bar ${progressPercentage === 100 ? "done" : ""}`}
                                                    style={{
                                                        maxWidth: `${progressPercentage}%`,
                                                    }}
                                                />
                                                <div className="file-progress-percentage">
                                                    {progressPercentage}%
                                                </div>
                                            </div>
                                        )} */}
                                        {/* {file.processing && isNaN(progressPercentage) && (
                                            <div className="file-processing">
                                                Processing...
                                            </div>
                                        )} */}
                                        {/* <div className={`file-duration ${!allowAddingFiles || progressPercentage === 100 ? "show" : ""}`}>
                                            {formatDuration(file.duration)}
                                        </div> */}
                                        {/* {progressPercentage === 100 && (
                                            <div className="file-upload-finished">
                                                Uploaded
                                            </div>
                                        )} */}
                                    {/* </div>
                                )} */}
                                {onRemoveFile && (
                                    <button
                                        className="file-remove"
                                        onClick={() => onRemoveFile(file)}
                                    >
                                        REMOVE
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FileList;