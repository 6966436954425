import OrderList from "./components/OrderList";

import { useOrders } from "./hooks/useOrders";

import "./Home.css";

function Home() {

    const { items: orders, isLoading } = useOrders();

    return (
        <div className="page page-home">
            <OrderList
                orders={orders}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Home;