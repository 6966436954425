import { createContext, useCallback, useContext, useEffect, useState } from "react";

import * as API from "../api";
import { getOrderDefaults } from "../customers";
import { generateOrderNameBasedOnFiles, getFileExtension } from "../utils";

import { useSessionStorage } from "./useStorage";
import { useTemplates } from "./useTemplates";
import { useFileFormats } from "./useFileFormats";

export function useOrdersInternal({ enabled = false }) {
    const [newOrder, setNewOrder] = useSessionStorage("new-order", getOrderDefaults());
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState(null);

    const fileFormats = useFileFormats();
    const templates = useTemplates({ enabled });
    
    useInitCompanyAndTemplateDefaults(newOrder, setNewOrder, templates/*, companies*/, enabled);

    useEffect(
        () => {
            (async () => {
				if (enabled) {
					// HACK until we implement pagination
					const orders1 = await API.getOrders({ isTemplate: false, pageIndex: 0 });
					const orders2 = await API.getOrders({ isTemplate: false, pageIndex: 1 });
					const orders = [...orders1, ...orders2];
					setItems(orders);
					setIsLoading(false);
				}
            })();
        },
        [enabled]
    );

    const get = useCallback(
        (id) => {
            return items.find((item) => item.id === id);
        },
        [items]
    );

    const cancelNewOrder = useCallback(
        () => {
            setNewOrder(getOrderDefaults());
        },
        [setNewOrder]
    );

    const add = useCallback(
        async (order) => {
            setItems((items) => [...items, order]);
        },
        []
    );

    const del = useCallback(
        async (id) => {
            await API.removeOrder(id);
            setItems((items) => items.filter((item) => item.id !== id));
        },
        []
    );

    const remove = useCallback(
        async (id) => {
            setItems((items) => items.filter((item) => item.id !== id));
        },
        []
    );

    const create = useCallback(
        async () => {
            setIsLoading(true); // separate isLoading for create?
			try {
				const orderTemplate = newOrder.type;
				let orderItems = orderTemplate.orderItems.map((orderItem) => {
					const item = { ...orderItem };
					delete item.id;
					if (newOrder.separateDeliveryDates) {
						const deliveryDateForLanguage = newOrder.requestedDeliveryDates[orderItem.targetLanguage.id];
						item.requestedDeliveryDate = deliveryDateForLanguage ?? newOrder.requestedDeliveryDate;
					} else {
						item.requestedDeliveryDate = newOrder.requestedDeliveryDate;
					}
					item.outputFileFormat = newOrder.outputFileFormat;
					return item;
				});

				orderItems = orderItems.filter((orderItem) => {
					const languageIsSelected = newOrder.targetLanguages.some((language) => orderItem.targetLanguage.id === language.id);
					return languageIsSelected;
				});
				
				const { id: orderId } = await API.copyOrder(orderTemplate.id, {
					name: newOrder.name ?? generateOrderNameBasedOnFiles(newOrder.files),
					requestedDeliveryDate: newOrder.requestedDeliveryDate,
					additionalInformation: newOrder.additionalInformation,
					properties: {
						IMDbId: newOrder.imdbId,
					},
					sourceLanguages: newOrder.sourceLanguages,
					orderItems,
				});

                const createdOrder = await API.getOrder(orderId);
                const createdOrderAssetPromises = createdOrder.assets.map((asset) => API.getAsset(asset.id));
                const assets = await Promise.all(createdOrderAssetPromises);

				const assetsWithFiles = [];
				for (const asset of assets) {
					const assetFiles = newOrder.files.filter((file) => {
						const fileFormat = getFileExtension(file.fileObject);
						return asset.allowedFileFormats.some((allowedFileFormat) => {
                            const formatWithExtension = fileFormats.data?.find((format) => format.id === allowedFileFormat.id);
                            return formatWithExtension.extension === fileFormat;
                        });
					});
					if (assetFiles.length) {
						assetsWithFiles.push({
							...asset,
							files: assetFiles,
						});
					}
				}

                add(createdOrder);
				setNewOrder(getOrderDefaults());
                return {
                    createdOrder,
                    assetsWithFiles,
                };
			} catch (e) {
				console.error(e);
				window.alert("An error occured while creating your order, please try again.");
                return {
                    createdOrder: null,
                    assetsWithFiles: [],
                };
			} finally {
				setIsLoading(false);
			}
        },
        [newOrder, setNewOrder, add, fileFormats]
    );

    return {
        items,
        get,
        create,
        add,
        remove,
        del,
        isLoading,
        newOrder,
        setNewOrder,
        cancelNewOrder,
    };
}

export const OrdersContext = createContext();

export function useOrders() {
    return useContext(OrdersContext);
}

function useInitCompanyAndTemplateDefaults(order, setOrder, templates) {
	// Set default company when companies are loaded
	useEffect(
		() => {
			// if (companies.items?.length && !order.invoiceDestination) {
			if (templates.companies?.length && !order.invoiceDestination) {
				setOrder((prevOrder) => ({
					...prevOrder,
					// invoiceDestination: companies.items[2].id,
					invoiceDestination: templates.companies[0].id,
				}));
			}
		},
		// [order, setOrder, companies]
		[order, setOrder, templates]
	);

	// Set default template when a company is selected
	useEffect(
		() => {
			if (order.invoiceDestination && templates.templatesByCompany) {
				const companyTemplates = templates.templatesByCompany[order.invoiceDestination];
				if (companyTemplates && !companyTemplates.some((template) => template.name === order.type?.name)) {
					const firstTemplate = companyTemplates[0];
					if (firstTemplate) {
						setOrder((prevOrder) => ({
							...prevOrder,
							type: firstTemplate,
							sourceLanguages: firstTemplate.sourceLanguages,
							targetLanguages: firstTemplate.orderItems.map(oI => oI.targetLanguage),
							outputFileFormat: firstTemplate.orderItems[0].outputFileFormat,
						}));
					}
				}
			}
		},
		[order, setOrder, templates]
	);
}