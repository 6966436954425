// import { useEffect } from "react";

import { useUploads } from "../hooks/useUploads";
import { useAuth } from "../hooks/useAuth";

import { Blocker } from "./Blocker";

import "./UploadStatus.css";

function UploadStatus() {
    const { isLoggedIn } = useAuth();
    const uploads = useUploads();

    // useEffect(
    //     () => {
    //         console.log("uploads.orderUploads", uploads.orderUploads);
    //     },
    //     [uploads.orderUploads]
    // );
    
    if (!isLoggedIn) {
        return null;
    }

    const hasUploadsNotFinished = uploads.orderUploads.some((orderUpload) => {
        return orderUpload.assetsWithFiles.some((asset) => {
            return asset.files.some((file) => {
                return file.loadedBytes < file.size;
            });
        });
    });

    return (
        <div className="upload-status">
            {/* {uploads.orderUploads.map(({ order, files }) => {
                console.log("order", order);
                return (
                    <div key={order.id} className="order-upload">
                        <h2>{order.name}</h2>
                        <ul>
                            {files.map((file) => {
                                const progressPercentage = Math.round(file.loadedBytes / file.size * 100);
                                return (
                                    <li key={file.fileObject.name}>
                                        {file.fileObject.name} - {progressPercentage}%
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })} */}
            <Blocker isActive={hasUploadsNotFinished} text="Files are being uploaded, if you navigate away, the order will be cancelled." />
        </div>
    );
}

export default UploadStatus;





// import { useState } from "react";

// import "./FileUploadStatus.css";

// function FileUploadStatus(props) {
//     const [expand, setExpand] = useState(false);

//     const {
//         files = [],
//         // fileUploadStarted = false,
//     } = props;

//     if (!files?.length) {
//         return null;
//     }

//     const onExpandToggle = () => {
//         setExpand(!expand);
//     };

//     const totalFileSize = files.reduce((total, file) => total + file.size, 0);
//     const totalLoadedBytes = files.reduce((total, file) => total + file.loadedBytes, 0);
//     const totalProgressPercentage = Math.round(totalLoadedBytes / totalFileSize * 100);

//     const showTotalProgress = !isNaN(totalProgressPercentage) && !expand;
//     const showFileProgress = expand && files.length > 0;

//     return (
//         <div className="file-upload-status">
//             {(showTotalProgress || showFileProgress) && (
//                 <p>
//                     File upload progress&nbsp;-&nbsp;
//                     <a onClick={onExpandToggle}>{expand ? "Less" : "More"} info</a>
//                 </p>
//             )}
//             {showTotalProgress && (
//                 <div className="total-progress-container">
//                     <div className="total-progress">
//                         <div
//                             className={`file-progress-bar ${totalProgressPercentage === 100 ? "done" : ""}`}
//                             style={{
//                                 maxWidth: `${totalProgressPercentage}%`,
//                             }}
//                         />
//                         <div className="file-progress-percentage">
//                             {totalProgressPercentage}%
//                         </div>
//                     </div>
//                 </div>
//             )}
//             {showFileProgress && files.map((file, index) => {
//                 const progressPercentage = Math.round(file.loadedBytes / file.size * 100);
//                 return (
//                     <div className="file" key={file.name ?? index}>
//                         <div className="img-wrapper">
//                             <img src={file.thumbnailURL} />
//                         </div>
//                         <div className="file-info">
//                             <div className="file-name">{file.name}</div>
//                             <div className="file-progress">
//                                 {!isNaN(progressPercentage) && (
//                                     <>
//                                         <div
//                                             className={`file-progress-bar ${progressPercentage === 100 ? "done" : ""}`}
//                                             style={{
//                                                 maxWidth: `${progressPercentage}%`,
//                                             }}
//                                         />
//                                         <div className="file-progress-percentage">
//                                             {progressPercentage}%
//                                         </div>
//                                     </>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 );
//             })}
//         </div>
//     );
// }

// export default FileUploadStatus;