import React from "react";
import ReactDOM from "react-dom/client";
import dayjs from "dayjs";
import dayjsMinMaxPlugin from "dayjs/plugin/minMax";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App.jsx";

import "./index.css";

dayjs.extend(dayjsMinMaxPlugin);

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://bfb3f50b77923e6cd78b58f69a4166a2@o10627.ingest.us.sentry.io/4507906182938624",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>	
	</React.StrictMode>
);
