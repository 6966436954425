import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import Button from "./Button";
import FileList from "./FileList";
import Spinner from "./Spinner";

import { useUploads } from "../hooks/useUploads";
import { useOrderAssets } from "../hooks/useOrderAssets";

import "./OrderList.css";

function OrderList(props) {
    const {
        orders,
        isLoading,
        // color,
        // secondaryColor,
    } = props;
    console.log("orders", orders);

    const navigate = useNavigate();

    const sortedOrders = orders
        ?.sort((oA, oB) => -dayjs(oA.created).diff(oB.created))
        .sort((oA, oB) => -dayjs(oA.requestedDeliveryDate).diff(oB.requestedDeliveryDate)) ?? [];
    
    const uploads = useUploads();

    return (
        <div className="order-list">
            <div className="heading">
                <h1>My orders</h1>
                <Button
                    label="Create order"
                    onClick={() => {
                        navigate("/order");
                    }}
                />
            </div>
            {isLoading && (
                <Spinner loading={true} />
            )}
            {orders !== null && !orders?.length && (
                <p className="top-info">No orders yet.</p>
            )}
            {orders?.length > 0 && (
                <p className="top-info">Here are your orders, click on an order to see more info.</p>
            )}
            <div className="orders">
                {sortedOrders.map((order) => {
                    return (
                        <Order
                            key={order.orderGuid ?? order.id}
                            order={order}
                            upload={uploads.orderUploads.find((upload) => upload.order.id === order.id)}
                        />
                    );
                })}
            </div>

            {orders?.length > 0 && (
                <p>Do you need to modify an order?<br/>Please contact us at <a href="mailto:team@junefirst.tv">team@junefirst.tv</a>.</p>
            )}
        </div>
    );
}

export default OrderList;

function Order({ order, upload }) {
    const [expand, setExpand] = useState(false);
    const orderEl = useRef(null);

    const isUploading = upload?.assetsWithFiles.some((asset) => {
        return asset.files.some((file) => file.loadedBytes !== undefined && !file.completed);
    });
    let uploadProgress = 0;
    if (isUploading) {
        const totalBytes = upload.assetsWithFiles.reduce((total, asset) => {
            return total + asset.files.reduce((total, file) => total + file.size, 0);
        }, 0);
        const loadedBytes = upload.assetsWithFiles.reduce((total, asset) => {
            return total + asset.files.reduce((total, file) => total + file.loadedBytes, 0);
        }, 0);
        uploadProgress = Math.round(loadedBytes / totalBytes * 100);
    }

    useEffect(
        () => {
            if (Math.abs(dayjs(order.created).diff()) < 2000) {
                setTimeout(() => {
                    orderEl.current?.classList.add("new-order");
        
                    setTimeout(() => {
                        orderEl.current?.classList.remove("new-order");
                    }, 3000);
                });
            }
        },
        [orderEl, order]
    );

    const orderAssets = useOrderAssets({ order, enabled: expand });
    const files = orderAssets.data?.reduce((files, asset) => {
        return files.concat(asset.files ?? []);
    }, []) ?? [];

    return (
        <div className="order" ref={orderEl}>
            <div className="order-info" onClick={() => setExpand(!expand)}>
                <div className="order-name">
                    {order.name ?? "<Unnamed order>"}
                    {isUploading && (
                        <span className="uploading">Uploading {uploadProgress}%</span>
                    )}
                </div>
                <div className="order-details">
                    <div className="order-requested-delivery-date">Requested delivery by {order.requestedDeliveryDate ? dayjs(order.requestedDeliveryDate).format("YYYY-MM-DD") : "???"}</div>
                    {expand && (
                        <div>
                            <div className="order-created">Created {dayjs(order.created).format("YYYY-MM-DD HH:mm")}</div>
                            <div className="order-id">Order ID: {order.orderGuid ?? order.id}</div>
                            <div className="order-files">
                                <FileList
                                    files={files}
                                />
                            </div>
                            <div>
                                <Button
                                    label="Cancel order"
                                    size="small"
                                    color="var(--color-4-primary)"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const reason = window.prompt("Please provide a reason for cancelling this order:");
                                        if (reason) {
                                            console.log("reason", reason);
                                            window.alert("Order cancelled. (This is a mockup, so nothing really happened.)");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
