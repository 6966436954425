import React from "react";

// https://usehooks.com/useLocalStorage/
export const useLocalStorage = createStorageHook("localStorage");
export const useSessionStorage = createStorageHook("sessionStorage");

function createStorageHook(storageType = "localStorage") {
    const storage = window[storageType];
    return function(key, initialValue, useJSON = true) {
        const _retrieveValue = () => {
            try {
                // Get from local storage by key
                const item = storage.getItem(key);
                // Parse stored value or if none return initialValue
                if (item) {
                    return useJSON ? JSON.parse(item) : item;
                }
                return initialValue;
            } catch (error) {
                // If error also return initialValue
                console.log(error);
                storage.removeItem(key);
                return initialValue;
            }
        }

        // State to store our value
        // Pass initial state function to useState so logic is only executed once
        const [storedValue, setStoredValue] = React.useState(_retrieveValue);

        React.useEffect(() => {
            setStoredValue(_retrieveValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [key]);

        // Return a wrapped version of useState's setter function that
        // persists the new value to storage.
        const setValue = (newValue) => {
            try {
                // Save state
                setStoredValue((currentValue) => {
                    // Allow value to be a function so we have same API as useState
                    const valueToStore = typeof newValue === "function" ? newValue(currentValue) : newValue;
                    // Save to storage
                    storage.setItem(key, useJSON ? JSON.stringify(valueToStore) : valueToStore);
                    return valueToStore;
                });
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        };
    
        return [storedValue, setValue];
    }
}