import dayjs from "dayjs";

// import { getUserRoles } from "./authUtils";
import { dayjsAddBusinessDays } from "./utils";

// const customers = {
//     "Njuta": {
//         orderDefaults: {
//             files: [],
//             additionalInformation: "",
//             requestedDeliveryDate: dayjsAddBusinessDays(dayjs(), 10).format("YYYY-MM-DD"),
//             requestedDeliveryDates: [],
//             separateDeliveryDates: false,
//         },
//         formConfig: {
//             subtitleFormatOptions: false,
//             transcript: false,
//             delivery: false,
//         },
//     },
//     "PWC Sweden": {
//         orderDefaults: {
//             files: [],
//             transcript: true,
//             subtitleFormat: ["file"],
//             additionalInformation: "",
//             requestedDeliveryDate: dayjsAddBusinessDays(dayjs(), 1).format("YYYY-MM-DD"),
//             requestedDeliveryDates: [],
//             separateDeliveryDates: false,
//         },
//         formConfig: {
//             subtitleFormatOptions: true,
//             transcript: false,
//             delivery: false,
//         },
//     }
// };

export function getOrderDefaults() {
    return {
        files: [],
        additionalInformation: "",
        requestedDeliveryDate: dayjsAddBusinessDays(dayjs(), 10).format("YYYY-MM-DD"),
        requestedDeliveryDates: [],
        separateDeliveryDates: false,
    };
    // const customer = getUserCustomer();
    // return customers[customer]?.orderDefaults ?? {};
}

export function getFormConfig() {
    return {
        subtitleFormatOptions: false,
        transcript: false,
        delivery: false,
    };
    // const customer = getUserCustomer();
    // return customers[customer]?.formConfig;
}

// const rolePrefix = "orders.Customer.";
// function getUserCustomer() {
//     const roles = getUserRoles();
//     const customer = roles.find(r => r.startsWith(rolePrefix))?.substring(rolePrefix.length);
//     return customer;
// }
