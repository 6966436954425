import axios from "axios";

import { API_BASE_URL } from "./config";
import { APITOKEN_STORAGE_KEY_PREFIX, getUserFromStorage, USERTOKEN_STORAGE_KEY, validateToken } from "./authUtils";

const ASSETS_API = "assets";
const ORDERS_API = "orders";
const ENTITIES_API = "entities";

// https://vodhub.junecomet.com/api/orders/swagger/index.html
// https://vodhub.junecomet.com/api/assets/swagger/index.html

export async function getOrder(id) {
    return await get({
        api: ORDERS_API,
        path: `orders/${id}`,
    });
}

export async function createOrder(data) {
    return await post({
        api: ORDERS_API,
        path: "orders/import",
        data,
    });
}

export async function copyOrder(id, data) {
    return await post({
        api: ORDERS_API,
        path: `orders/${id}/copy`,
        data,
    });
}

export async function changeOrderState(id, data) {
    return await post({
        api: ORDERS_API,
        path: `orders/${id}/changestate`,
        data,
    });
}

export async function getOrders(filters) {
    return await get({
        api: ORDERS_API,
        path: "orders",
        filters,
    });
}

export async function removeOrder(id) {
    return await del({
        api: ORDERS_API,
        path: `orders/${id}`,
    });
}

export async function getLanguages() {
    return await get({
        api: ENTITIES_API,
        path: "languages",
    });
}

export async function authenticate(data) {
    return await post({
        api: ENTITIES_API,
        path: "logins/authenticate",
        data,
        skipAuth: true,
    });
}

// export async function register(data) {
//     return await post({
//         path: "shield/logins/register",
//         data,
//         skipAuth: true,
//     });
// }

export async function getCompanies() {
    return await get({
        api: ORDERS_API,
        path: "companies",
    });
}

export async function getAsset(id) {
    return await get({
        api: ASSETS_API,
        path: `assets/${id}`,
    });
}

export async function createAssetFile(id, data) {
    return await post({
        api: ASSETS_API,
        path: `assets/${id}/files`,
        data,
    });
}

export async function getFileFormats() {
    return await get({
        api: ASSETS_API,
        path: "fileformats",
    });
}

export async function getFileUploadSAS(assetId, fileId) {
    return await get({
        api: ASSETS_API,
        path: `assets/${assetId}/files/${fileId}/upload`,
    });
}

// Remove the default item from the list which has to be there in every API response...
const get = async (args) => {
    let res = await makeRequest("get", args);
    if (Array.isArray(res)) {
        res = res.filter(item => item.id !== "00000000-0000-0000-0000-000000000000");
    }
    return res;
};
const put = async (args) => await makeRequest("put", args);
const post = async (args) => await makeRequest("post", args);
const del = async (args) => await makeRequest("delete", args);

async function makeRequest(method, { api, path, data, skipAuth = false, filters }) {
    let authHeader = null;
    if (!skipAuth) {
        // const apiToken = await getApiToken(api);
        // authHeader = `Bearer ${apiToken}`;
        authHeader = `Bearer ${getUserFromStorage()}`;
    }
    let queryParams = "";
    if (filters) {
        queryParams = "?" + new URLSearchParams(filters).toString();
    }
    const result = await axios({
        method,
        url: `${API_BASE_URL}/${api}/${path}${queryParams}`,
        data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader,
        },
    });
    return result.data?.result ?? result.data;
}

// async function getApiToken(api) {
//     const apiTokenStorageKey = `${APITOKEN_STORAGE_KEY_PREFIX}${api}`;
//     const jwt = localStorage.getItem(apiTokenStorageKey);
//     if (jwt && validateToken(jwt)) {
//         return jwt;
//     }

//     // Get a new token if we don't have one
//     const userToken = localStorage.getItem(USERTOKEN_STORAGE_KEY);
//     const result = await axios({
//         method: "get",
//         url: `${API_BASE_URL}/shield/services/${api}/token`,
//         headers: {
//             "Authorization": `Bearer ${userToken}`,
//         },
//     });

//     if (result?.data) {
//         localStorage.setItem(apiTokenStorageKey, result.data);
//         return result.data;
//     }

//     return null;
// }