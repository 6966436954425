import { useCallback, useEffect, useState } from "react";

import { getOrders } from "../api";

export function useTemplates({ enabled = false }) {
    const [items, setItems] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [templatesByCompany, setTemplatesByCompany] = useState(null);

    useEffect(
        () => {
            (async () => {
                if (!enabled) {
                    return;
                }

                const orders = await getOrders({ isTemplate: true });
                setItems(orders);

                const companies = orders
                    .map((order) => order.company)
                    .filter((company, index, self) => self.findIndex(c => c.id === company.id) === index);
                setCompanies(companies);

                const templatesByCompany = companies.reduce((obj, company) => ({
                    ...obj,
                    [company.id]: orders.filter((order) => order.company.id === company.id),
                }), {});
                setTemplatesByCompany(templatesByCompany);
            })();
        },
        [enabled]
    );

    const get = useCallback(
        (id) => {
            return items.find((item) => item.id === id);
        },
        [items]
    );

    return {
        items,
        get,
        companies,
        templatesByCompany,
    };
}