import dayjs from "dayjs";

// Format bytes to GB, MB, KB, B
export function formatSize(sizeInBytes) {
    const units = ["GB", "MB", "KB", "B"];
    let size = sizeInBytes;
    let unitIndex = 0;
    while (size > 1024) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
}

// Format duration in seconds to hours, minutes and seconds (HH:MM:SS)
export function formatDuration(durationInSeconds) {
    const hours = `${Math.floor(durationInSeconds / 3600)}`.padStart(2, "0");
    const minutes = `${Math.floor((durationInSeconds % 3600) / 60)}`.padStart(2, "0");
    const seconds = `${Math.floor(durationInSeconds % 60)}`.padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
}

export function dayjsAddBusinessDays(date, days) {
    let result = date;
    while (days > 0) {
        result = result.add(1, "day");
        if (result.day() !== 0 && result.day() !== 6) {
            days--;
        }
    }
    return result;
}

export function dayjsDiffBusinessDays(date1, date2) {
    let result = 0;
    let earlierDate = dayjs.min(date1, date2);
    const laterDate = dayjs.max(date1, date2);
    while (earlierDate.isBefore(laterDate, "day")) {
        earlierDate = earlierDate.add(1, "day");
        if (earlierDate.day() !== 0 && earlierDate.day() !== 6) {
            result++;
        }
    }
    return result;
}

export function generateOrderNameBasedOnFiles(files, emptyOrderText = "") {
    if (!files?.length) {
        return emptyOrderText;
    }

    if (files.length === 1) {
        return files[0].name;
    }

    return `${files[0].name} + ${files.length - 1} more ${files.length === 2 ? "file" : "files"}`;
}

export function getFileExtension(file) {
    const extensionRegex = /\.[^.]+$/;
    const extension = file.name.match(extensionRegex)[0];
    return extension;
}

/* Quote from Fathima:
        Express charges apply if we need to deliver a multilanguage order in less than 10 business days
        or if we need to deliver a single language order in less than 5 business days.
        The charge is negotiated on a project basis.
*/
export function isExpressChargeApplicable(order, formConfig) {
    return false;
    const typeConfig = formConfig.typeOptions.find((option) => option.key === order.type);

    // Single language order
    if (!order.separateDeliveryDates) {
        return dayjsDiffBusinessDays(order.requestedDeliveryDate, dayjs()) < typeConfig.defaultDeliveryDateDaysForSingleLanguageOrders;
    }

    // Multi language order
    if (order.separateDeliveryDates) {
        const deliveryDates = Object.values(order.requestedDeliveryDates).map((date) => dayjs(date));
        const earliestDeliveryDate = dayjs.min(deliveryDates) ?? dayjs(order.requestedDeliveryDate);
        return dayjsDiffBusinessDays(earliestDeliveryDate, dayjs()) < typeConfig.defaultDeliveryDateDaysForMultiLanguageOrders;
    }

    return false;
}
